import React, { useRef, useState } from "react";
import specialityData from '../helperJson/speciality.json'
import subsSpecialityData from '../helperJson/subSpeciality.json'
import facilityData from '../helperJson/facility.json'
import jobClassData from '../helperJson/jobClass.json';
import expertiseJson from '../helperJson/expertise.json'
import Swal from 'sweetalert2'


const SendCandidate = () => {
    const INITIAL_SITE={ medifis: false, stafferlink: false, shiftwise: false, favorite: false,ahsa:false }
    const [site, setSite] = useState(INITIAL_SITE)
    const [fields, setFields] = useState({})
    const [speciality, setSpeciality] = useState(null)
    const [facility, setFacility] = useState(null)
    const [expertise,setExpertise]=useState(expertiseJson)
    const [selectedExpertise,setSelectedExpertise]=useState([])
    const prospectId = useRef(null);


    const siteOnChange = (e) => setSite({ ...INITIAL_SITE, [e.target.id]: e.target.checked })
    const searchOnJazzhr = () => {
        if (prospectId.current.value) {
            const apiKey = 'zuwcK99CUekwsoE0iuDS4gciGp3tcSRW'
            const id = prospectId.current.value
            // prospect_20200320175635_HVN30IE73YTAQ1BC

            Swal.showLoading()
            fetch(`https://api.resumatorapi.com/v1/applicants/${id}?apikey=${apiKey}`, {
                "headers": { "accept": "application/json, text/javascript, */*; q=0.01" },
                "method": "GET"
            }).then((response) => response.json())
                .then((data) => {
                    if (data.first_name) {
                        let [zipcode, state, city] = data?.location?.split(' ')?.reverse()
                        setFields({ ...data, zipcode, state, city })
                        Swal.fire('User found!', '', 'success')

                    } else {
                        Swal.fire('User not found!', '', 'error')
                    }
                }).catch((ex) => {
                    Swal.fire('User not found!', '', 'error')
                    console.log('parsing failed', ex)
                })
        }

    }

    const specialityChange = (e) => {
        if (e.target.value) {
            setSpeciality(e.target.value)
        }
    }

    const facilityChange = (e) => {
        if (e.target.value) {
            setFacility(e.target.value)
        }
    }

    const onExpertiseSearch=(e)=>{
        const target=e.target
        const query=target.value
        setExpertise(()=>{
            if(query===''||!query){
                return expertiseJson
            }
            return  expertiseJson.filter((exp)=>exp.label.toLowerCase().includes(query.toLowerCase()))
        })
    }

    const onSelectExpertise=(e)=>{
        const target=e.target
        if(target.checked){
            setSelectedExpertise([...selectedExpertise,target.value])
        }else{ 
            setSelectedExpertise((prev)=> prev.filter((p)=>p!==target?.value))
        }
    }


    return (
        <div>
            <div className="containerr">
                <div className="heading">

                    <h4>Fill Data To Send User</h4>
                </div>
                <div className="inner-container">
                    <div className="c-box">
                        <form action="" method="">

                            <div className="d-flex justify-content-center p-4">
                                <input ref={prospectId} type='text' className="form-control mr-2" placeholder="prospect id" />
                                <button onClick={searchOnJazzhr} className="btn btn-info" type="button">Search</button>
                            </div>

                            <div className="d-flex justify-content-center p-3 mb-3 bg-gradient-dark rounded text-light align-items-center" >
                                <p>Send To: </p>
                                <div className="form-check">
                                    <input type="radio" name="site" id="medifis" data-link='https://vms.medefis5.com/' onChange={siteOnChange} />
                                    <label className="form-check-label" for="medifis"> Medifis</label>
                                </div>
                                <div className="form-check">
                                    <input type="radio" name="site" id="stafferlink" data-link='https://careerstaff.stafferlink.com/login.aspx' onChange={siteOnChange} />
                                    <label className="form-check-label" for="stafferlink"> Stafferlink</label>
                                </div>
                                <div className="form-check">
                                    <input type="radio" name="site" id="shiftwise" data-link='https://secure.shiftwise.net/esp/login/login.aspx' onChange={siteOnChange} />
                                    <label className="form-check-label" for="shiftwise"> Shiftwise</label>
                                </div>
                                <div className="form-check">
                                    <input type="radio" name="site" id="favorite" data-link='https://msp-fav.agency.staffbridge.com/index.html#!/subcontractor/login' onChange={siteOnChange} />
                                    <label className="form-check-label" for="favorite"> Favourite</label>
                                </div>
                                 <div className="form-check">
                                    <input type="radio" name="site" id="ahsa" data-link='https://ahsa.triovms.com/Account/Login?message=None' onChange={siteOnChange} />
                                    <label className="form-check-label" for="ahsa"> AHSA</label>
                                </div>
                            </div>

                            {
                                site.medifis &&
                                <div className="row p-2">
                                    <div className="col-md-3">
                                        <select className="form-control" onChange={specialityChange} id='speciality'>
                                            {specialityData.map((opt) => <option value={opt.value}>{opt.text}</option>)}
                                        </select>
                                    </div>
                                    <div className="col-md-12 mt-3">
                                        {
                                            speciality &&
                                           <>
                                            <span>Sub Speciality</span>
                                            <div style={{ height: '150px', overflow: 'auto', border: '1px solid #d5cbcb' }} className='bg-white text-dark d-flex flex-column rounded'>
                                                {
                                                    subsSpecialityData[speciality].map((sub) => {
                                                        return <label className=" d-flex align-items-center" ><input className='sub-speciality w-auto sub-speciality' type="checkbox" id={sub.value} />{sub.text}</label>

                                                    })
                                                }
                                            </div>
                                            </>
                                        }
                                    </div>

                                </div>
                            }

                            {
                                site.stafferlink && <div className="row p-2">
                                <div className="col-md-3">
                                    <select className="form-control" onChange={facilityChange} id='facility'>
                                        {facilityData.map((opt) => <option key={opt.value} value={opt.value}>{opt.text}</option>)}
                                    </select>
                                </div>
                                <div className="col-md-3">
                                    <select className="form-control" id='jobClass'>
                                        {jobClassData[facility]?.map((opt) => <option key={opt.value} value={opt.value}>{opt.text}</option>)}
                                    </select>
                                </div>

                                </div>
                            }
                             {
                                site.ahsa &&
                                <div className="row p-2">
                                   
                                    <div className="col-md-12 mt-3">
                                        {
                                           <>
                                            <span>Expertise</span>
                                            <input onChange={onExpertiseSearch} type='text' className='form-control' placeholder="search expertise" />
                                            <div style={{ height: '150px', overflow: 'auto', border: '1px solid #d5cbcb' }} className='bg-white text-dark d-flex flex-column rounded'>
                                                {
                                                    expertise.map((exp) => {
                                                        return <label className=" d-flex align-items-center" >
                                                            <input  
                                                                onChange={onSelectExpertise} 
                                                                checked={selectedExpertise.includes(exp.value)} 
                                                                id={exp.value} 
                                                                value={exp.value} 
                                                                className='w-auto expertise' 
                                                                type="checkbox"  />{exp.label}</label>

                                                    })
                                                }
                                            </div>
                                            </>
                                        }
                                    </div>

                                </div>
                            }


                            <div className="pair">
                                <div className="form-group field-container"  >
                                    <label className="bold label" htmlFor={'fname'} > First Name </label>
                                    <div className="flex">
                                        <div className="img-wrapper">
                                            <img src={'images/user.png'} alt='First Name' className="icon" />
                                        </div>
                                        <input type="text" value={fields.first_name} name={'fname'} placeholder={'First Name'} id='fname' className="form-control input-field" />
                                    </div>
                                </div>
                                <div className="form-group field-container"  >
                                    <label className="bold label" htmlFor={'lname'} > Last Name </label>
                                    <div className="flex">
                                        <div className="img-wrapper">
                                            <img src={'images/user.png'} alt='Last Name' className="icon" />
                                        </div>
                                        <input type="text" value={fields.last_name} name={'lname'} placeholder={'Last Name'} id='lname' className="form-control input-field" />
                                    </div>
                                </div>
                            </div>

                            <div className="pair">
                                <div className="form-group field-container"  >
                                    <label className="bold label" htmlFor={'mname'} > Middle Name </label>
                                    <div className="flex">
                                        <div className="img-wrapper">
                                            <img src={'images/user.png'} alt='Middle Name' className="icon" />
                                        </div>
                                        <input type="text" name={'mname'} placeholder={'Middle Name'} id='mname' className="form-control input-field" />
                                    </div>
                                </div>
                                <div className="form-group field-container"  >
                                    <label className="bold label" htmlFor={'altfname1'} > Alt F- Name 1 </label>
                                    <div className="flex">
                                        <div className="img-wrapper">
                                            <img src={'images/user.png'} alt='Alternate First Name 1' className="icon" />
                                        </div>
                                        <input type="text" value={fields.first_name} name={'altfname1'} placeholder={'Alternate First Name 1'} id='altfname1' className="form-control input-field" />
                                    </div>
                                </div>
                            </div>
                            <div className="pair">
                                <div className="form-group field-container"  >
                                    <label className="bold label" htmlFor={'altlname1'} > Alt L- Name 1 </label>
                                    <div className="flex">
                                        <div className="img-wrapper">
                                            <img src={'images/user.png'} alt='Alternate Last Name 1' className="icon" />
                                        </div>
                                        <input type="text" value={fields.last_name} name={'altlname1'} placeholder={'Alternate Last Name 1'} id='altlname1' className="form-control input-field" />
                                    </div>
                                </div>
                                <div className="form-group field-container"  >
                                    <label className="bold label" htmlFor={'altfname2'} > Alt F- Name 2 </label>
                                    <div className="flex">
                                        <div className="img-wrapper">
                                            <img src={'images/user.png'} alt='Alternate First Name 2' className="icon" />
                                        </div>
                                        <input type="text" value={fields.first_name} name={'altfname2'} placeholder={'Alternate First Name 2'} id='altfname2' className="form-control input-field" />
                                    </div>
                                </div>
                            </div>
                            <div className="pair">
                                <div className="form-group field-container">
                                    <label className="bold label" htmlFor="altlname2"> Alt L-Name 2 </label>
                                    <div className="flex">
                                        <div className="img-wrapper">
                                            <img className="icon" src="images/user.png" alt="altlname2" />
                                        </div>
                                        <input type="text" value={fields.last_name} className="form-control input-field" name="altlname2" placeholder="Alternate Last Name 2" id="altlname2"
                                        />
                                    </div>
                                </div>
                                <div className="form-group field-container">
                                    <label className="bold label" htmlFor="ssn"> SSN </label>
                                    <div className="flex">
                                        <div className="img-wrapper">
                                            <img className="icon" src="images/geo-alt-fill.svg" alt="ssn" />
                                        </div>
                                        <input type="number" className="form-control input-field number" min="0" placeholder="SSN" name="ssn" id="ssn" />
                                    </div>
                                </div>
                            </div>
                            <div className="pair">
                                <div className="form-group field-container">
                                    <label className="bold label" htmlFor="last4ssn"> Last 4 SSN </label>
                                    <div className="flex">
                                        <div className="img-wrapper">
                                            <img className="icon" src="images/geo-alt-fill.svg" alt="last4ssn" />
                                        </div>
                                        <input type="number" val className="form-control input-field number" name="last4ssn" placeholder="Last 4 SSN" id="last4ssn" maxLength="4" minLength="4" min="0" />
                                    </div>
                                </div>
                                <div className="form-group field-container">
                                    <label className="bold label" htmlFor="state">  State </label>
                                    <div className="flex">
                                        <div className="img-wrapper">
                                            <img className="icon" src="images/location.png" alt="state" />
                                        </div>
                                        <input type="text" value={fields.state} className="form-control input-field" name="state" placeholder="State" id="state" />
                                    </div>
                                </div>
                            </div>
                            <div className="pair">
                                <div className="form-group field-container">
                                    <label htmlFor="city" className="bold label">City</label>
                                    <div className="flex">
                                        <div className="img-wrapper">
                                            <img className="icon" src="images/location.png" alt="city" />
                                        </div>
                                        <input type="text" value={fields.city} className="form-control input-field" name="city" placeholder="City" id="city" />
                                    </div>
                                </div>
                                <div className="form-group field-container">
                                    <label className="bold label" htmlFor="street">Street</label>
                                    <div className="flex">
                                        <div className="img-wrapper">
                                            <img className="icon" src="images/road-with-broken-line.png" alt="street" />
                                        </div>
                                        <input type="text" value={fields.address} className="form-control input-field" name="street" placeholder="Street" id="street" />
                                    </div>
                                </div>
                            </div>
                            <div className="pair">
                                <div className="form-group field-container">
                                    <label htmlFor="house_no" className="bold label"> House No </label>
                                    <div className="flex">
                                        <div className="img-wrapper">
                                            <img className="icon" src="images/home.png" alt="house" />
                                        </div>
                                        <input type="number" className="form-control input-field number" placeholder="House No" name="house_no" min="0" id="house_no" />
                                    </div>
                                </div>
                                <div className="form-group field-container">
                                    <label htmlFor="zip" className="bold label">Zip </label>
                                    <div className="flex">
                                        <div className="img-wrapper">
                                            <img className="icon" src="images/zip-code.png" alt="zip" />
                                        </div>
                                        <input type="number" value={fields.zipcode} className="form-control input-field number" placeholder="Zip" name="zip" min="0" id="zip" maxLength="5" />
                                    </div>
                                </div>
                            </div>
                            <div className="pair">
                                <div className="form-group field-container">
                                    <label htmlFor="keyword" className="bold label">Keyword</label>
                                    <div className="flex">
                                        <div className="img-wrapper">
                                            <img className="icon" src="images/keyword.png" alt="keyword" />
                                        </div>
                                        <input required type="text" className="form-control input-field" placeholder="Keyword" name="keyword" id="keyword" />
                                    </div>
                                </div>
                                <div className="form-group field-container">
                                    <label htmlFor="email" className="bold label"> Email </label>
                                    <div className="flex">
                                        <div className="img-wrapper">
                                            <img className="icon" src="images/email.png" alt="email" />
                                        </div>
                                        <input type="email" value={fields.email} className="form-control input-field" placeholder="Email" name="email" id="email" />
                                    </div>
                                </div>
                            </div>
                            <div className="pair" >
                                <div className="form-group  field-container">
                                    <label htmlFor="email" className="bold label"> Birthday </label>
                                    <div className="flex">
                                        <div className="img-wrapper">
                                            <img className="icon" src="images/email.png" alt="phonne" />
                                        </div>
                                        <input className="form-control date-size"  type="date" name="bday" id="bday" />
                                    </div>
                                </div>
                                <div className="form-group field-container">
                                    <label htmlFor="email" className="bold label"> Phone </label>
                                    <div className="flex">
                                        <div className="img-wrapper">
                                            <img className="icon" src="images/email.png" alt="phonne" />
                                        </div>
                                        <input type="text" value={fields.phone} className="form-control input-field" placeholder="Phone" name="phone" id="phone" />
                                    </div>
                                </div>
                                {/* <div style={{ display: "none" }} /> */}
                            </div>

                            <label style={{ marginLeft: "52px" }} className="bold" htmlFor="birthplace">
                                Birthplace:
                            </label>
                            <div className="pair">
                                <div className="form-group field-container">
                                    <label htmlFor="country" className="bold label">Country </label>
                                    <div className="flex">
                                        <div className="img-wrapper">
                                            <img className="icon" src="images/germany.png" alt="country" />
                                        </div>
                                        <input type="text" className="form-control input-field" placeholder="Country" name="country" id="country" />
                                    </div>
                                </div>
                                <div className="form-group field-container">
                                    <label htmlFor="state2" className="bold label">State</label>
                                    <div className="flex">
                                        <div className="img-wrapper">
                                            <img className="icon" src="images/location.png" alt="state2" />
                                        </div>
                                        <input type="text" value={fields.state} className="form-control input-field" name="state2" placeholder="State" id="state2" />
                                    </div>
                                </div>
                            </div>
                            <div className="pair">
                                <div className="form-group field-container fieldWidth">
                                    <label htmlFor="city2" className="bold label">City</label>
                                    <div className="flex">
                                        <div className="big-img-wrapper">
                                            <img className="icon" src="images/location.png" alt="city2" />
                                        </div>
                                        <input type="text" value={fields.city} className="form-control input-field" name="city2" placeholder="City" id="city2" />
                                    </div>
                                </div>
                                <div className="invisible"></div>
                            </div>

                            {/* <div style={{ marginLeft: "56px" }} className="form-group">
                                <label className="bold" htmlFor="gender">
                                    Gender:
                                </label>
                                <br />
                                <div style={{ display: "flex" }}>
                                    <span style={{ display: "flex", alignItems: "center", justifyContent: "start", marginRight: "20px", }} >
                                        <label htmlFor="male">Male</label>
                                        <input style={{ height: "15px", width: "15px" }} type="radio" id="male" name="gender" />
                                    </span>
                                    <span style={{ display: "flex", alignItems: "center", justifyContent: "start", marginRight: "20px" }} >
                                        <label htmlFor="female">Female</label>
                                        <input style={{ height: "15px", width: "15px" }} type="radio" id="female" name="gender" />
                                    </span>
                                    <span style={{ display: "flex", alignItems: "center", justifyContent: "start", marginRight: "20px" }}>
                                        <label htmlFor="other">Other</label>
                                        <input style={{ height: "15px", width: "15px" }} type="radio" id="other" name="gender" />
                                    </span>
                                </div>
                            </div> */}
                            {/* below fields are hidden. */}
                            <input type="text" name="created_at" readOnly id="created_at" style={{ display: "none" }} />
                            <input type="text" name="user_id" readOnly id="user_id" style={{ display: "none" }} />

                            <div className="verify"></div>
                            <div id='extStartBtnDiv'></div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SendCandidate;

// you can download the icons from flaticon.com
